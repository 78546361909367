import {
  AddIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  Button,
  CloseIcon,
  FilesPdfIcon,
  Flex,
  InfoIcon,
  Input,
  MenuButton,
  MoreIcon,
  SearchIcon,
  Text,
  Popup,
  BulletsIcon,
} from "@fluentui/react-northstar";
import React, {useState} from "react";
import {useEffect} from "react";
import {ICommitteeModel} from "../models/ICommitteeModel";
import CommitteeStatus from "./Atoms/CommitteeStatus";
import {Panel, PanelType} from "@fluentui/react/lib/Panel";

import {Provider as ThemeProvider} from "@fluentui/react-northstar";
import {TeamsThemeHelper} from "../helpers";

import styles from "./CommitteeList.module.scss";
import CommitteeOverviewHub from "./CommitteeOverviewHub";
import {ISelfServiceConfig} from "../models/ISelfServiceConfig";
import {CommitteeService} from "../services/CommitteeService";
import {MemberService} from "../services/MemberService";
import {JoinTeamDialog} from "./Atoms/JoinTeamDialog";
import {Contact} from "./Atoms/Contact";
import {IMemberModel} from "../models/IMemberModel";
import {Icon} from "@fluentui/react/lib/Icon";
import CompanyCommitteesList from "./CompanyCommitteesList";

interface ICommitteeListProps {
  committees: ICommitteeModel[] | null;
  config: ISelfServiceConfig | null;
  currentUserId: string | null;
}

type sortFields =
  | "name"
  | "technicalStatus"
  | "division"
  | "organisationalUnit"
  | "responsibleZveiSort";

interface ISort {
  field: sortFields;
  asc: boolean;
}

export const CommitteeList = (props: ICommitteeListProps) => {
  const [filterValue, setFilterValue] = useState("");
  const [sort, setSort] = useState<ISort>({field: "name", asc: true});
  const [openPanel, setOpenPanel] = useState(false);
  const [openCompanyCommitteesPanel, setOpenCompanyCommitteesPanel] =
    useState(false);
  const [selectedCommittee, setSelectedCommittee] =
    useState<ICommitteeModel | null>(null);
  const [, setSelectedCommitteeMembers] = useState<IMemberModel[] | null>(null);
  const [committeeDatasheetLinks, setCommitteeDatasheetLinks] = useState<
    string[]
  >([]);
  const [isInSelectedTeam, setIsInSelectedTeam] = useState(true);
  const [showJoinDialog, setShowJoinDialog] = useState(false);
  const [selectedContact, setSelectedContact] = useState<IMemberModel | null>(
    null
  );
  const [showContactDialog, setShowContactDialog] = useState(false);
  const [companyRelevantCommittees, setCompanyRelevantCommittees] = useState<
    ICommitteeModel[] | null
  >(null);
  //const [mgtClass, setMgtClass] = useState('mgt-light');

  const openDetailPanel = function () {
    setOpenPanel(true);
  };

  const openCompanyRelevantCommittees = async function () {
    const relCommittees =
      await CommitteeService.getUserCompanyRelevantCommittees(
        props.currentUserId || ""
      );
    setCompanyRelevantCommittees(relCommittees);
    setOpenCompanyCommitteesPanel(true);
  };

  const setTableButtons = function (committee: ICommitteeModel) {
    if (committee !== selectedCommittee) {
      setSelectedCommittee(committee);
    } else {
      setSelectedCommittee(null);
    }
  };

  useEffect(() => {
    const reloadCommitteeDatasheetLinks = async () => {
      setCommitteeDatasheetLinks([]);
      if (selectedCommittee !== null) {
        const newDatasheetLinks =
          await CommitteeService.getCommitteeDatasheetLinks(
            selectedCommittee?.id
          );
        setCommitteeDatasheetLinks(newDatasheetLinks);
      }
    };

    const reloadMembers = async () => {
      setIsInSelectedTeam(true);
      setSelectedCommitteeMembers([]);
      if (selectedCommittee !== null) {
        const membersResult = await MemberService.getMembers(
          selectedCommittee?.id,
          true
        );
        const members = membersResult.members;

        const isInTeam =
          members.filter((member) => member.userId === props.currentUserId)
            .length > 0;
        setIsInSelectedTeam(isInTeam);
        setSelectedCommitteeMembers(members);
      }
    };

    reloadCommitteeDatasheetLinks();
    reloadMembers();
  }, [selectedCommittee, props.currentUserId]);

  const getSortedAndFiltered = function (
    committees: ICommitteeModel[] | null
  ): ICommitteeModel[] {
    let sorted =
      committees?.sort((c1, c2) =>
        (c1[sort.field] || "").localeCompare(c2[sort.field] || "")
      ) || [];
    if (!sort?.asc) {
      sorted?.reverse();
    }
    return sorted?.filter(
      (ci) =>
        ci.name?.toLowerCase().includes(filterValue) ||
        ci.organisationalUnit?.toLowerCase().includes(filterValue) ||
        ci.description?.toLowerCase().includes(filterValue) ||
        ci.topics?.toLowerCase().includes(filterValue) ||
        ci.division?.toLowerCase().includes(filterValue) ||
        ci.objectives?.toLowerCase().includes(filterValue) ||
        ci.representingCompanies?.toLowerCase().includes(filterValue) ||
        ci.responsibleZvei?.some((r) =>
          r.displayName.toLowerCase().includes(filterValue)
        )
    );
  };

  const updateSort = function (field: sortFields) {
    if (sort.field !== field) {
      setSort({field, asc: true});
    } else {
      setSort({field, asc: !sort.asc});
    }
  };

  return (
    <div className={styles.committeeListContainer}>
      <Flex space="between">
        <Flex gap="gap.small">
          <Button
            hidden={true}
            icon={<AddIcon />}
            content="Neu beantragen"
            iconPosition="before"
            primary
            onClick={(ev) => window.open(props.config?.requestCommitteeLink)}
          />
          <Button
            text
            icon={<InfoIcon />}
            content="Informationen anzeigen"
            iconPosition="before"
            onClick={(ev) => openDetailPanel()}
            disabled={!selectedCommittee}
          />
          <Button
            text
            icon={<FilesPdfIcon />}
            content="PDF Datenblatt anzeigen"
            iconPosition="before"
            onClick={(ev) => window.open(committeeDatasheetLinks[1])}
            disabled={!selectedCommittee || !committeeDatasheetLinks[1]}
          />
          <Button
            text
            icon={<BulletsIcon />}
            content="Firmenrelevante Gremien"
            iconPosition="before"
            onClick={(ev) => openCompanyRelevantCommittees()}
          />
        </Flex>
        <Input
          icon={<SearchIcon />}
          placeholder="Gremium suchen"
          iconPosition="start"
          onChange={(event, value) =>
            setFilterValue(value ? value.value?.toLowerCase() : "")
          }
        />
      </Flex>
      <div
        className={[styles.clList, TeamsThemeHelper.getThemeString()].join(" ")}
      >
        <div className={styles.clListHeader}>
          <div className={styles.clListSelected}></div>
          <div className={styles.clListInfo}></div>
          <div className={styles.clListName}>
            <Button
              iconPosition="after"
              icon={
                sort.field === "name" ? (
                  sort.asc ? (
                    <ArrowDownIcon />
                  ) : (
                    <ArrowUpIcon />
                  )
                ) : (
                  <></>
                )
              }
              text
              content="Name"
              onClick={() => {
                updateSort("name");
              }}
            />
          </div>
          <div className={styles.clListStatus}>
            <Button
              iconPosition="after"
              icon={
                sort.field === "technicalStatus" ? (
                  sort.asc ? (
                    <ArrowDownIcon />
                  ) : (
                    <ArrowUpIcon />
                  )
                ) : (
                  <></>
                )
              }
              text
              content="Status"
              onClick={() => {
                updateSort("technicalStatus");
              }}
            />
          </div>
          <div className={styles.clListDivision}>
            <Button
              iconPosition="after"
              icon={
                sort.field === "division" ? (
                  sort.asc ? (
                    <ArrowDownIcon />
                  ) : (
                    <ArrowUpIcon />
                  )
                ) : (
                  <></>
                )
              }
              text
              content="Bereich"
              onClick={() => {
                updateSort("division");
              }}
            />
          </div>
          <div className={styles.clListOrganisationalUnit}>
            <Button
              iconPosition="after"
              icon={
                sort.field === "organisationalUnit" ? (
                  sort.asc ? (
                    <ArrowDownIcon />
                  ) : (
                    <ArrowUpIcon />
                  )
                ) : (
                  <></>
                )
              }
              text
              content="Org.-Einheit"
              onClick={() => {
                updateSort("organisationalUnit");
              }}
            />
          </div>
          <div className={styles.clListResponsibleZvei}>
            <Button
              iconPosition="after"
              icon={
                sort.field === "responsibleZveiSort" ? (
                  sort.asc ? (
                    <ArrowDownIcon />
                  ) : (
                    <ArrowUpIcon />
                  )
                ) : (
                  <></>
                )
              }
              text
              content="Kontakt im ZVEI"
              onClick={() => {
                updateSort("responsibleZveiSort");
              }}
            />
          </div>
        </div>
        <div className={styles.clListContent}>
          {getSortedAndFiltered(props.committees).map((committee) => {
            return (
              <div
                className={[
                  styles.clListItem,
                  selectedCommittee?.id === committee.id
                    ? styles.clListIsSelected
                    : "",
                ].join(" ")}
                onClick={() => setTableButtons(committee)}
              >
                <div
                  className={[
                    styles.clListSelected,
                    styles.clCircleContainer,
                  ].join(" ")}
                >
                  <div className={styles.clCircle}>
                    <Icon
                      className={[
                        styles.clIcon,
                        styles.clListUnSelectedIcon,
                      ].join(" ")}
                      iconName="CircleRing"
                    ></Icon>
                    <Icon
                      className={[
                        styles.clIcon,
                        styles.clListSelectedIcon,
                      ].join(" ")}
                      iconName="StatusCircleCheckmark"
                    ></Icon>
                  </div>
                </div>
                <div className={styles.clListInfo}>
                  <InfoIcon
                    outline
                    className={styles.clInfoIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCommittee(committee);
                      openDetailPanel();
                    }}
                  />
                </div>
                <div className={styles.clListName}>
                  <Flex
                    className={styles.clTitleCell}
                    space="between"
                    vAlign="center"
                  >
                    <Text
                      styles={{cursor: "pointer"}}
                      content={committee.name}
                      onClick={(
                        e: React.MouseEvent<HTMLSpanElement, MouseEvent>
                      ) => {
                        e.stopPropagation();
                        setSelectedCommittee(committee);
                        openDetailPanel();
                      }}
                    />
                    <MenuButton
                      styles={{cursor: "pointer"}}
                      trigger={
                        <MoreIcon
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedCommittee(committee);
                          }}
                        />
                      }
                      menu={[
                        <Text
                          content={"Informationen anzeigen"}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            openDetailPanel();
                          }}
                          className={styles.MenuLink}
                        />,
                        <Text
                          content={"Team beitreten"}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            setShowJoinDialog(!isInSelectedTeam && true);
                          }}
                          className={styles.MenuLink}
                          disabled={
                            isInSelectedTeam ||
                            committee.technicalStatus !== "Active"
                          }
                        />,
                        <Text
                          content={"PDF-Datenblatt aufrufen"}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            window.open(committeeDatasheetLinks[1]);
                          }}
                          className={styles.MenuLink}
                          disabled={!committeeDatasheetLinks[1]}
                        />,
                        <Text
                          content={"Datenblatt nach Word exportieren"}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            window.open(committeeDatasheetLinks[0]);
                          }}
                          className={styles.MenuLink}
                          disabled={!committeeDatasheetLinks[0]}
                        />,
                      ]}
                      on="click"
                    />
                  </Flex>
                </div>
                <div className={styles.clListStatus}>
                  <CommitteeStatus status={committee.technicalStatus} />
                </div>
                <div className={styles.clListDivision}>
                  {committee.division}
                </div>
                <div className={styles.clListOrganisationalUnit}>
                  {committee.organisationalUnit}
                </div>
                <div className={styles.clListResponsibleZvei}>
                  {committee.responsibleZvei &&
                    committee.responsibleZvei.length > 0 && (
                      <>
                        <Popup
                          position="below"
                          align="center"
                          content={
                            <Contact
                              committee={committee}
                              member={committee.responsibleZvei[0]}
                            />
                          }
                          pointing
                          trigger={
                            <Text
                              styles={{cursor: "pointer"}}
                              content={`${
                                committee.responsibleZvei[0].title
                                  ? committee.responsibleZvei[0].title
                                  : ""
                              } ${committee.responsibleZvei[0].firstName} ${
                                committee.responsibleZvei[0].familyName
                              }`.trim()}
                            />
                          }
                        />
                      </>
                    )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <JoinTeamDialog
        open={showJoinDialog}
        onClose={() => setShowJoinDialog(false)}
        committee={selectedCommittee}
        joinTeamUrl={props.config?.joinTeamLink}
      />
      <Panel
        isOpen={openPanel && !showJoinDialog}
        type={PanelType.custom}
        customWidth="80vw"
        isLightDismiss
        onDismiss={() => setOpenPanel(false)}
        className={styles.clPanel}
      >
        <ThemeProvider
          theme={TeamsThemeHelper.getTheme(TeamsThemeHelper.getThemeString())}
        >
          <div className={styles.clPanelContent}>
            <div className={styles.clPanelHeader}>
              <h3>Team Informationen</h3>
              <CloseIcon
                className={styles.closeIcon}
                onClick={(ev) => setOpenPanel(false)}
              />
            </div>
            <CommitteeOverviewHub groupId={selectedCommittee?.groupId || ""} />
            <Flex gap="gap.smaller">
              <Button
                content="Team beitreten"
                primary
                onClick={(ev) => setShowJoinDialog(true)}
                disabled={
                  isInSelectedTeam ||
                  selectedCommittee?.technicalStatus !== "Active"
                }
              />
              <Button
                content="Schließen"
                secondary
                onClick={(ev) => setOpenPanel(false)}
              />
            </Flex>
          </div>
        </ThemeProvider>
      </Panel>
      <Panel
        isOpen={openCompanyCommitteesPanel && !showJoinDialog}
        type={PanelType.custom}
        customWidth="80vw"
        isLightDismiss
        onDismiss={() => setOpenCompanyCommitteesPanel(false)}
        className={styles.clPanel}
      >
        <ThemeProvider
          theme={TeamsThemeHelper.getTheme(TeamsThemeHelper.getThemeString())}
        >
          <div className={styles.clPanelContent}>
            <div className={styles.clPanelHeader}>
              <h3>Firmenrelevante Gremien</h3>
              <CloseIcon
                className={styles.closeIcon}
                onClick={(ev) => setOpenCompanyCommitteesPanel(false)}
              />
            </div>
            <CompanyCommitteesList
              committees={companyRelevantCommittees!}
            ></CompanyCommitteesList>
            <Button
              content="Schließen"
              secondary
              onClick={(ev) => setOpenCompanyCommitteesPanel(false)}
            />
          </div>
        </ThemeProvider>
      </Panel>
    </div>
  );
};
