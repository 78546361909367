import styles from "./CompanyCommitteeList.module.scss";
import {ICommitteeModel} from "../models/ICommitteeModel";
import {DetailsList, IColumn, SelectionMode} from "@fluentui/react";

interface CompanyCommitteesListProps {
  committees: ICommitteeModel[];
}

export default function CompanyCommitteesList(
  props: CompanyCommitteesListProps
) {
  const columns: IColumn[] = [
    {key: "displayName", name: "Name", fieldName: "displayName", minWidth: 100},
    {key: "company", name: "Firma", fieldName: "company", minWidth: 100},
  ];

  const memberItems = props.committees.flatMap((committee) =>
    committee.members!.map((member) => ({
      displayName: member.displayName,
      company: member.company,
      groupKey: committee.id,
      groupName: committee.name,
    }))
  );

  const committeeGroups = props.committees.map((committee) => ({
    ...committee,
    key: committee.id,
    count: memberItems.filter((member) => member.groupKey === committee.id)
      .length,
    startIndex:
      memberItems.findIndex((member) => member.groupKey === committee.id) ?? -1,
  }));

  return (
    <div className={styles.listContainer}>
      <DetailsList
        items={memberItems}
        groups={committeeGroups}
        columns={columns}
        groupProps={{
          showEmptyGroups: true,
        }}
        selectionMode={SelectionMode.none} // controls how/if list manages selection ( non, single, multiple)
        checkboxVisibility={2} //0 = on hover, 1 = always, 2 = hidden
      />
      {/* {props.committees.map((committee) => (
        <div>
          <p>{committee.name}</p>
          <ul>
            {committee.members?.map((member) => (
              <li>{member.displayName}</li>
            ))}
          </ul>
        </div>
      ))} */}
    </div>
  );
}
