import {xhr} from "../helpers/xhr";
import {ICommitteeModel} from "../models/ICommitteeModel";
import {IContactModel} from "../models/IContactModel";
import {MemberService} from "./MemberService";

export class CommitteeService {
  public static async getCommittees(): Promise<ICommitteeModel[]> {
    const response = await xhr(`committees`);
    return CommitteeService.parseArray(response.data);
  }

  public static async getCommittee(
    committeeId: string
  ): Promise<ICommitteeModel> {
    const response = await xhr(`committees/${committeeId}`);
    return CommitteeService.parse(response.data);
  }

  public static async getCommitteeDatasheetLinks(
    committeeId: string
  ): Promise<string[]> {
    const response = await xhr(`committees/${committeeId}/datasheetlinks`);
    return response.data;
  }

  public static async activateCommittee(
    committee: ICommitteeModel
  ): Promise<void> {
    await xhr(`committees/${committee.id}/activate`, {
      method: "POST",
    });
    return;
  }

  public static async getCommitteeContact(
    committeeId: string,
    contactId: string
  ): Promise<IContactModel> {
    const response = await xhr(
      `committees/${committeeId}/contacts/${contactId}`
    );
    return response.data;
  }

  public static async getUserCompanyRelevantCommittees(
    userId: string
  ): Promise<ICommitteeModel[]> {
    const response = await xhr(`committees/memberscommittees/${userId}`);
    return CommitteeService.parseArray(response.data);
  }

  private static parse(data: any): ICommitteeModel {
    const modified = data["modified"] ? new Date(data["modified"]) : undefined;
    var responsibleZvei = data["responsibleZvei"]
      ? MemberService.parseArray(data["responsibleZvei"])
      : undefined;
    return {
      id: data["id"],
      groupId: data["groupId"],
      name: data["name"],
      modified,
      description: data["description"],
      objectives: data["objectives"],
      optionalComments: data["optionalComments"],
      organisationalUnit: data["organisationalUnit"],
      division: data["division"],
      representingCompanies: data["representingCompanies"],
      responsibleZvei,
      responsibleZveiSort: responsibleZvei
        ?.map((rz) => {
          return rz.displayName;
        })
        .join(","),
      status: data["status"],
      topics: data["topics"],
      typeOfCommittee: data["typeOfCommittee"],
      technicalStatus: data["technicalStatus"],
      members: data["members"],
    } as ICommitteeModel;
  }

  private static parseArray(data: any[]): ICommitteeModel[] {
    if (data && data.length) {
      return data.map(CommitteeService.parse);
    }
    return [];
  }
}
